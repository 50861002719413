<template>
    <div>
        <van-overlay :show="true">
            <div class="wrapper" @click.stop>
                <van-form @submit="onSubmit">
                    <van-field
                            v-model="code"
                            name="code"
                            label="兑换码"
                            placeholder="请输入兑换码"
                            :rules="[{ required: true, message: '兑换码不可为空' }]"
                    />
                    <div style="margin: 16px;">
                        <van-button round block type="info" native-type="submit">提交</van-button>
                    </div>
                </van-form>
            </div>
        </van-overlay>
        <callPhone></callPhone>
    </div>
</template>
<script>
    import {verificationCode} from "../api/api";
    import callPhone from "../components/callPhone";

    export default {
        components:{
            callPhone
        },
        name: 'verification',
        data() {
            return {
                code: '',//兑换码
            }
        },
        methods: {
            onSubmit(e) {
                if (e.code === '') {
                    this.$notify({
                        type: 'warning',
                        message: '兑换码不能为空'
                    });
                }
                verificationCode(this.code)
                    .then(() => {
                        window.location.href = 'https://m.shanzhen.me/cooperation/customize_examcoupon_auth?comId=dfacebf833c64a829cb8bfcd4206664a&activityCode=ACT977964770'
                    })
            }
        }
    }
</script>
<style scoped>
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
</style>
